import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { loadingSelector } from "store/slices/game/gameSlice";
import { useSelector } from "react-redux";

const Loading = () => {
  const loadingInfo = useSelector(loadingSelector);

  return (
    <>
      {loadingInfo ? (
        <div className="absolute w-full h-full left-0 top-0 bg-opacity-70 bg-primary opacity-1 text-white flex justify-center items-center">
          <FontAwesomeIcon icon={faSpinner} spinPulse size="2xl" />
        </div>
      ) : null}
    </>
  );
};

export default Loading;
