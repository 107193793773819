import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

const Success = () => {
  return (
    <>
      <FontAwesomeIcon
        className="text-secondary"
        icon={faScrewdriverWrench}
        size="5x"
        fade
      />
      <div className="text-center">
        Geri bildiriminiz için teşekkürler en kısa zamanda ilgileneceğiz!
      </div>
    </>
  );
};
export default Success;
