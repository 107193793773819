import React from "react";

function Button({
  id,
  buttonIcon,
  buttonText,
  buttonClass = "",
  borderType = false,
  showMobileText = false,
  variant,
  isIcon = false,
  onClick = () => {},
  disabled = false,
}) {
  const handleButtonColor = () => {
    switch (variant) {
      case "primary":
        return "bg-primary";
      case "secondary":
        return "bg-secondary";
      case "purple":
        return "bg-purple";
      case "green":
        return "bg-green";
      case "red":
        return "bg-red";
      case "shadowPrimary":
        return "bg-primary shadow-primary";
      case "shadowSecondary":
        return "bg-secondary shadow-secondary";
      case "shadowPurple":
        return "bg-purple shadow-purple";
      case "shadowGreen":
        return "bg-green shadow-green";
      default:
        return "bg-transparent";
    }
  };
  const handleBorder = () => {
    if (borderType) return "border";
    return "";
  };

  const handleOnClick = () => {
    if (disabled) {
      return;
    } else {
      onClick();
    }
  };
  return (
    <div
      id={id}
      className={`flex items-center justify-center text-white space-x-2 shadow-3xl ${handleButtonColor()} ${handleBorder()} cursor-pointer rounded-lg m-1 h-12 ${
        disabled ? "cursor-not-allowed opacity-75" : "opacity-100"
      } ${buttonClass} `}
      onClick={handleOnClick}
      disabled={disabled}
    >
      {isIcon ? (
        buttonIcon
      ) : (
        <img className="" width={25} src={buttonIcon} alt="Icon" />
      )}
      <span className={`${!showMobileText && buttonText ? "block" : "hidden"}`}>
        {buttonText ?? null} {borderType}
      </span>
    </div>
  );
}

export default Button;
