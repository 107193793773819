import React from "react";
import PropTypes from "prop-types";

import InfoBox from "./InfoBox";

function HowToPlay({ id }) {
  return (
    <div
      id={id}
      className={`flex w-72 md:w-s6 border bg-primary items-center flex-col text-white space-y-10 shadow-3xl rounded-lg m-1}`}
    >
      <div className="flex flex-col space-y-5 p-3">
        <h1 className="font-bold text-lg text-center">Nasıl Oynanır?</h1>
        <InfoBox
          id="one"
          iconId="2"
          infoTitle="Alfabetik Sıra!"
          infoText="Bulman gereken meslek, sana verilen iki meslek arasında alfabetik sıraya göre yer alıyor."
        />
        <InfoBox
          id="two"
          iconId="3"
          infoTitle="Tahmin Et!"
          infoText="Bulman gereken mesleği yazdığında, listede hangi sıraya yerleştiğini ve tahminine ne kadar yaklaştığını göreceksin. Bu ipuçlarını değerlendirerek tahminlerini güçlendirebilirsin."
        />
      </div>
    </div>
  );
}

HowToPlay.propTypes = {
  id: PropTypes.string.isRequired,
};

export default HowToPlay;
