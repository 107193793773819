import Main from "pages/Layout/Main";
import Start from "pages/Modules/Start";
import Room from "pages/Modules/Room";
import { useSelector } from "react-redux";
import { gameIdSelector } from "store/slices/game/gameSlice";

const Game = () => {
  const gameId = useSelector(gameIdSelector);

  return <Main>{gameId ? <Room /> : <Start />}</Main>;
};
export default Game;
