import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function Popup({
  children,
  isOpen = false,
  handlePopupClose,
  PopupContentClass,
  PopupClass,
}) {
  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } fixed w-full h-full top-0 right-0 border`}
    >
      <div
        onClick={handlePopupClose}
        className="fixed w-full h-full z-40"
      ></div>
      <div
        className={`${PopupClass} w-72 md:w-1/3 popup p-6 fixed h-auto z-50 max-w-2xl rounded-2xl`}
      >
        <div className={`${PopupContentClass}`}>{children}</div>
        <div
          className="absolute cursor-pointer top-1 right-1 w-6 h-6 flex items-center justify-center text-white"
          onClick={handlePopupClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  PopupContentClass: PropTypes.string,
  PopupClass: PropTypes.string,
  children: PropTypes.any,
  handlePopupClose: PropTypes.func.isRequired,
};
