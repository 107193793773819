import {
  gameSliceReducer,
  gameSliceReducerName,
} from "store/slices/game/gameSlice";
import { gameApiReducer, gameApiReducerName } from "store/slices/game/gameApi";

const rootReducer = {
  [gameSliceReducerName]: gameSliceReducer,
  [gameApiReducerName]: gameApiReducer,
};

export default rootReducer;
