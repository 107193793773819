import { useState } from "react";
import { useSendReportMutation } from "store/slices/game/gameApi";
import { apiResHandler } from "utils/axiosBaseQuery";
import Entry from "./Entry";
import Success from "./Success";

const Report = () => {
  const [sendReport] = useSendReportMutation();
  const [reportText, setReportText] = useState("");
  const [isReported, setIsReported] = useState(false);

  const reportGame = () => {
    apiResHandler(
      sendReport({ data: { message: reportText } }),
      (data) => {
        setIsReported(true);
      },
      () => {
        console.log("hata");
      }
    );
  };

  const changeReportText = (text) => {
    setReportText(text);
  };

  return (
    <div className="text-white flex flex-col justify-center items-center gap-y-4 m-4">
      {isReported ? (
        <Success />
      ) : (
        <Entry
          reportGame={reportGame}
          reportText={reportText}
          changeReportText={changeReportText}
        />
      )}
    </div>
  );
};
export default Report;
