import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  game: {
    steps: [],
    gameId: "",
  },
  loading: false,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    updateGameId: (state, action) => {
      state.game.gameId = action.payload;
    },
    updateGame: (state, action) => {
      state.game = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearGame: (state) => {
      state.game = initialState.game;
    },
    updateGameWords: (state, action) => {
      state.game.steps = [...state.game.steps, action.payload];
    },
    changeGameWords: (state, action) => {
      state.game.steps = action.payload;
    },
  },
});

export const removeUserFromList = (word) => {
  return async (dispatch, getState) => {
    const wordList = getState().game.game.steps;
    const newWordList = wordList?.filter((words) => words.word !== word);
    dispatch(changeGameWords(newWordList));
  };
};

export const wordListSelector = (state) => state.game.game.steps;
export const gameIdSelector = (state) => state.game.game.gameId;
export const loadingSelector = (state) => state.game.loading;

export const {
  updateGame,
  updateLoading,
  updateGameId,
  updateGameWords,
  clearGame,
  changeGameWords,
} = gameSlice.actions;

export const gameSliceReducer = gameSlice.reducer;
export const gameSliceReducerName = gameSlice.name;

export default gameSlice.reducer;
