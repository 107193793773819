import logo from "assets/logo.jpeg";
import { Button } from "components";
import GameIcon from "assets/icons/game.svg";
import HowToPlay from "./HowToPlay";
import { useCreateGameMutation } from "store/slices/game/gameApi";
import { apiResHandler } from "utils/axiosBaseQuery";
import { storageItem } from "utils/localstorage";
import { useDispatch } from "react-redux";
import { updateGameId, updateLoading } from "store/slices/game/gameSlice";

const Start = () => {
  const [createGame] = useCreateGameMutation();
  const dispatch = useDispatch();

  const StartGame = () => {
    dispatch(updateLoading(true));
    apiResHandler(
      createGame(),
      (data) => {
        storageItem("gameId", data.gameId);
        dispatch(updateGameId(data.gameId));
      },
      () => {
        console.log("hata");
      }
    );
  };

  return (
    <div className="flex space-y-10 flex-col h-full w-full justify-center items-center">
      <img className="w-48 h-48 rounded-lg" src={logo} alt="logo" />
      <HowToPlay id="0" />
      <Button
        buttonClass="!w-40"
        variant="secondary"
        buttonText="OYNA"
        buttonIcon={GameIcon}
        onClick={StartGame}
      />
    </div>
  );
};
export default Start;
