import React from "react";

const InfoBox = ({ id, infoTitle, infoText }) => {
  return (
    <div id={id} className="flex space-x-5 items-center">
      <div className="flex flex-col space-1 justify-center">
        <span className="font-bold text-md">{infoTitle}</span>
        <span className="text-xs text-lightGray">{infoText}</span>
      </div>
    </div>
  );
};

export default InfoBox;
