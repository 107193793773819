import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "assets/styles/WordList.css";

const WordList = ({ words }) => {
  return (
    <TransitionGroup className="flex flex-col m-2 mt-14 md:mt-8 rounded h-full border overflow-y-scroll p-2 gap-2">
      {words.map((step) => (
        <CSSTransition key={step.word} timeout={1000} classNames="word-item">
          <div className="flex border border-darkGray rounded-full bg-primary justify-between items-center text-lightGray drop-shadow-xl">
            <div className="px-2">{step.word}</div>
            <div></div>
            <div className="flex items-center w-16 justify-between px-1 text-center bg-secondary rounded-full border">
              <div className="flex-1">
                <FontAwesomeIcon
                  icon={
                    step.rate === 0
                      ? faStar
                      : step.rate < 0
                      ? faChevronUp
                      : faChevronDown
                  }
                />
              </div>
              <div className="flex-1">{Math.abs(step.rate)}</div>
            </div>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default WordList;
