import { Alert, Button, Input, Popup } from "components";
import { getErrorMessage } from "constant/ResponseCodes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "assets/icons/send.svg";
import {
  useSendWordMutation,
  useLazyGetGameQuery,
  useCreateGameMutation,
} from "store/slices/game/gameApi";
import {
  clearGame,
  gameIdSelector,
  removeUserFromList,
  updateGame,
  updateGameId,
  updateGameWords,
  updateLoading,
  wordListSelector,
} from "store/slices/game/gameSlice";
import { apiResHandler } from "utils/axiosBaseQuery";
import { removeStoragedItem, storageItem } from "utils/localstorage";
import WordList from "./WordList";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Finish from "./Finish";
import Report from "./Report/Report";

const Room = () => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const [alert, setAlert] = useState({
    isActive: false,
    message: "",
    duration: 2000,
  });
  const [sendWord] = useSendWordMutation();
  const [createGame] = useCreateGameMutation();
  const initiatedRef = useRef(false);
  const runConfetti = useRef(false);
  const [showPopup, setShowPopup] = useState(false);
  const [word, setWord] = useState("");
  const [lastWord, setLastWord] = useState("");
  const [isReport, setIsReport] = useState(false);
  const steps = useSelector(wordListSelector);
  const gameId = useSelector(gameIdSelector);
  const [getGameById, { data: oldGameData }] = useLazyGetGameQuery();

  useEffect(() => {
    if (oldGameData?.steps) {
      dispatch(updateGame({ gameId, steps: oldGameData.steps }));
      dispatch(updateLoading(false));
      if (oldGameData.isFinished) {
        runConfetti.current = true;
        setIsReport(false);
        setShowPopup(true);
      }
    }
  }, [oldGameData, dispatch, gameId]);

  useEffect(() => {
    if (gameId) getGameById({ id: gameId });
  }, [gameId, getGameById]);

  useEffect(() => {
    if (!initiatedRef.current && steps?.length > 0 && !oldGameData.isFinished) {
      initiatedRef.current = true;
      dispatch(updateGameWords({ word: "???????", rate: 0 }));
    }
  }, [steps, dispatch]);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      submitWord();
    }
  };

  const submitWord = () => {
    apiResHandler(
      sendWord({ data: { gameId, word } }),
      (data) => {
        if (data.responseCode === 1001) {
          if (data.rate === 0) {
            setIsReport(false);
            setLastWord(word);
            setShowPopup(true);
            runConfetti.current = true;
            dispatch(removeUserFromList("???????"));
          }
          dispatch(
            updateGameWords({
              ...steps,
              word: data.word,
              rate: data.rate,
            })
          );
        } else {
          setAlert({
            ...alert,
            isActive: true,
            message: getErrorMessage(data.responseCode),
          });
        }
      },
      () => {
        console.log("Error occurred");
      }
    );
    setWord("");
  };

  const exitGame = () => {
    dispatch(clearGame());
    removeStoragedItem("gameId");
  };

  const openBug = () => {
    setIsReport(true);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const playAgain = () => {
    apiResHandler(
      createGame(),
      (data) => {
        storageItem("gameId", data.gameId);
        dispatch(updateGameId(data.gameId));
        window.location.reload();
      },
      () => {
        console.log("hata");
      }
    );
  };

  const sortedSteps = useMemo(() => {
    return steps?.slice().sort((a, b) => b.rate - a.rate) || [];
  }, [steps]);

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <WordList words={sortedSteps} />
        <div className="flex items-center">
          <Input
            value={word}
            onChange={(e) => setWord(e.target.value)}
            onKeyDown={handleEnterPress}
            placeholder="Meslek Giriniz..."
          />
          <Button
            buttonClass="!w-32"
            variant="secondary"
            buttonText="Gönder"
            showMobileText={true}
            buttonIcon={SendIcon}
            onClick={submitWord}
          />
        </div>
      </div>
      <Button
        buttonClass="w-8 h-8 absolute md:w-10 md:h-10 left-2 top-2 md:left-8 md:top-8"
        variant="secondary"
        buttonIcon={<FontAwesomeIcon size="lg" icon={faArrowLeft} />}
        isIcon={true}
        onClick={exitGame}
      />
      <Button
        buttonClass="w-8 h-8 absolute md:w-10 md:h-10 right-2 top-2 md:right-8 md:top-8"
        variant="red"
        buttonIcon={<FontAwesomeIcon size="lg" icon={faBug} />}
        isIcon={true}
        onClick={openBug}
      />
      <Alert
        duration={alert.duration}
        message={alert.message}
        showAlert={alert.isActive}
        handleOnClose={() => setAlert({ ...alert, isActive: false })}
      />
      <Confetti run={runConfetti.current} width={width} height={height} />
      <Popup
        PopupClass="bg-primary drop-shadow-2xl"
        handlePopupClose={closePopup}
        PopupContentClass=""
        isOpen={showPopup}
      >
        {isReport ? <Report /> : <Finish word={lastWord} onClick={playAgain} />}
      </Popup>
    </>
  );
};

export default Room;
