import { Button } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faCrown } from "@fortawesome/free-solid-svg-icons";

const Finish = ({ word, onClick }) => {
  return (
    <div className="text-white flex flex-col justify-center items-center gap-y-4 m-4">
      <FontAwesomeIcon
        className="text-secondary"
        size="5x"
        icon={faCrown}
        bounce
      />
      <div>{word}</div>
      <Button
        buttonClass="bg-primary w-full"
        variant="secondary"
        buttonText="TEKRAR OYNA"
        buttonIcon={<FontAwesomeIcon icon={faRotateLeft} />}
        isIcon={true}
        onClick={onClick}
      />
    </div>
  );
};
export default Finish;
