import React, { useEffect, useState } from "react";
import { Transition } from "@tailwindui/react";
import PropTypes from "prop-types";

const Alert = (props) => {
  const {
    position = "bottom-right",
    message = "",
    showAlert = false,
    duration = 2000,
    handleOnClose = () => {},
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(showAlert);
  }, [showAlert]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, duration);
    } else {
      handleOnClose?.();
    }
  }, [isOpen]);

  const handlePosition = () => {
    switch (position) {
      case "center":
        return "top-1/2 left-1/2 transform -translate-x-1/2";
      case "bottom-left":
        return "bottom-0 left-0 mb-4 ml-4";
      case "top-left":
        return "left-0 top-0 mt-4 ml-4";
      case "top-right":
        return "top-0 right-0 mt-4 mr-4";
      default:
        return "bottom-20 left-1/2 transform -translate-x-1/2";
    }
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-700"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`fixed z-50 bg-darkGray drop-shadow-2xl flex items-center w-fit px-6 py-4 rounded ${handlePosition()}`}
        role="alert"
      >
        <div className="text-center text-white text-md" weight="semibold">
          {message}
        </div>
      </div>
    </Transition>
  );
};

Alert.propTypes = {
  position: PropTypes.string,
  message: PropTypes.string,
  showAlert: PropTypes.bool,
  duration: PropTypes.number,
  handleOnClose: PropTypes.func,
};

export default Alert;
