import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Game from "pages/Views/Game";
import { useDispatch } from "react-redux";
import { getStoragedItem } from "utils/localstorage";
import { updateGameId } from "store/slices/game/gameSlice";

function Router() {
  const dispatch = useDispatch();
  const gameId = getStoragedItem("gameId");

  useEffect(() => {
    gameId?.value && dispatch(updateGameId(gameId.value));
  }, [gameId, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Game />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
