const ErrorMessages = {
  2001: "Bir hata oluştu.",
  2002: "Oyun bulunamadı.",
  2003: "Kelime bulunamadı.",
  2004: "Gelen kelime boş.",
  2005: "Gelen mesaj boş.",
  2006: "Kelime daha önce tahmin edilmiş.",
};

export const getErrorMessage = (code) => {
  return ErrorMessages[code] || "Bilinmeyen bir hata oluştu.";
};
