import { Button } from "components";
import SendIcon from "assets/icons/send.svg";

const Entry = ({ reportText, reportGame, changeReportText }) => {
  return (
    <>
      <textarea
        className="w-full text-primary h-32 text-xs md:text-md p-2"
        value={reportText}
        onChange={(e) => changeReportText(e.target.value)}
        placeholder="Lütfen Karşılaştığınız Sorunu Yazınız..."
      ></textarea>
      <Button
        buttonClass="bg-primary w-full"
        variant="secondary"
        buttonText="GÖNDER"
        buttonIcon={SendIcon}
        onClick={reportGame}
      />
    </>
  );
};
export default Entry;
